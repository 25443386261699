<div>
   <button aria-label="Print Order"
                  class="mb-2"
                  role="button"
                  variant="secondary"
                  data-analytics-click="print-order"
                  type="button"
                  sprkButton
                  (click)="onClick()"> Print Order
   <sprk-icon iconType="print"
               data-analytics-click="print-order-view"
               [additionalClasses]="additionalClasses"
               class="cursor-pointer"
               (click)="onClick()">
   </sprk-icon>
   </button>
</div>