import { EnvironmentTypes } from './environment-types.enum';

export class PortalEnvironment {
  public readonly catchApiUrl: string;
  constructor(environmentType: EnvironmentTypes) {
    if (environmentType === EnvironmentTypes.prod) {
      this.catchApiUrl = 'https://closingportal.rocketmortgage.com/api';
    } 
    else {
      this.catchApiUrl = `https://closingportal.${environmentType}.rocketmortgage.com/api`;
    }
  }
}
