import { EnvironmentTypes } from './models/environment-types.enum';
import { PortalEnvironment } from './models/portal-environment';
import { IEnvironment } from './models/IEnvironment';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const PORTAL_ENVIRONMENT = new PortalEnvironment(EnvironmentTypes.beta);

export const environment: IEnvironment = {
  production: false,
  catchApiUrl: PORTAL_ENVIRONMENT.catchApiUrl,
  eClosingUrl: "https://testclose.eoriginal.com",
  authConfig: {
    auth0Domain: 'sso.beta.authrock.com',
    auth0ClientId: 'zxhZE3w9uokP4EYxPDIdT6Sys4ti8jkQ',
    audience: 'urn:ql-api:catch-api-203912:beta'
  },
  splitConfig: {
    clientId: "vqrgb0uqa63kdcrmto4kkloukbftcmivmaja"
  },
  adobeAnalyticsConfig: {
    scriptUrl: "//assets.adobedtm.com/b14636b10888/a7f35d584cc6/launch-f34f3a339e80-staging.min.js"
  },
  liveChatConfig: {
    sfdcInstanceUrl: "https://ql--qluat.sandbox.my.salesforce.com",
    liveAgentSetupFlow: "https://ql--qluat.sandbox.my.salesforce-sites.com/liveAgentSetupFlow",
    orgId: "00D4F0000000w7n",
    sBaseLiveAgentContentURL: "https://c.la2-c1cs-ia5.salesforceliveagent.com/content",
    sDeploymentId: "5727X0000004CqY",
    sButtonId: "5737X0000008Q9Q",
    sBaseLiveAgentURL: "https://d.la2-c1cs-ia5.salesforceliveagent.com/chat",
    sEswJsUrl: "https://ql--qluat.my.salesforce.com/embeddedservice/5.0/esw.min.js",
    eswLiveAgentDevName: "EmbeddedServiceLiveAgent_Parent04I7X000000009hUAA_17b1bc483d3"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
